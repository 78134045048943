<template>
  <div class="upload_file px-4 py-2">
    <div class="py-4 title text-black   border-b border-gray-300 font-bold">
      上传文件
    </div>
    <div class="content_item ">
      <a-table :columns="columns" :data-source="list" :scroll="{ x: 1000 }">
        <template slot="pictures" slot-scope="pictures">
          <div class="flex">
            <div
              class="img_box rounded mr-1"
              v-for="(picture,index) in pictures"
              :key="index + picture"
            >
              <img :src="picture" alt="" />
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "订单编号",
    dataIndex: "odd",
    key: "odd",
    fixed:'left',
    width:280,
    scopedSlots: { customRender: "odd" },
  },
  {
    title: "商户名称",
    dataIndex: "name",
    key: "name",
    width:180
  },
  {
    title: "商户编码",
    dataIndex: "coding",
    key: "coding",
    width:150
  },
  {
    title: "图片信息",
    dataIndex: "pictures",
    key: "pictures",
    scopedSlots: { customRender: "pictures" },
  },
];
export default {
  data() {
    return {
      columns,
      list: [
        {
          key: "1",
          odd: "14894618418611489461841861",
          name: "商户名称商户名称",
          coding: "66666666666",
          pictures: [
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
          ],
        },
        {
          key: "2",
          odd: "14894618418611489461841861",
          name: "商户名称商户名称",
          coding: "66666666666",
          pictures: [
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
          ],
        },
        {
          key: "3",
          odd: "14894618418611489461841861",
          name: "商户名称商户名称",
          coding: "66666666666",
          pictures: [
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
          ],
        },
        {
          key: "4",
          odd: "14894618418611489461841861",
          name: "商户名称商户名称",
          coding: "66666666666",
          pictures: [
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202010/bpic21430.jpg",
            "https://scpic.chinaz.net/files/pic/pic9/202101/bpic22201.jpg",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 432px;
}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
tr:hover {
  cursor: pointer;
}
.upload_file {
  th:first-child{
    text-align: left !important;
  }
  th:last-child {
    text-align: right;
  }
  tr td:last-child {
    div {
      justify-content: flex-end;
    }
  }
  .img_box {
    height: 48px;
    width: 60px;
    overflow: hidden;
    background: #ccc;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}
</style>
